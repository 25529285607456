import { EventSearch } from '@eventbrite/discover-utils';
import {
    DestinationEvent,
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import { deepKeysToCamel } from '@eventbrite/transformation-utils';
import { isEmpty, isObject, isString, omitBy } from 'lodash';
import {
    OrganicEventSearchApiResponse,
    type OrganicEventSearchFiltersApiPayload,
} from '../api/searchOrganicEvents';
import { PaginationData } from '../types';

/**
 Receives an object and removes empty objects, arrays, and strings
 but will leave explicit false declarations.

 @param objectToPrune - object
 {gunter: [], rick: false, morty: ''}

 @return object
 {rick: false}
*/
export const pruneObject = (objectToPrune: Object) =>
    omitBy(
        objectToPrune,
        (value) => (isString(value) || isObject(value)) && isEmpty(value),
    );

export const parseSearchCriteria = (
    apiSearchCriteria: OrganicEventSearchFiltersApiPayload,
): EventSearch => {
    return deepKeysToCamel(apiSearchCriteria);
};

export type SearchResponseEvents = {
    events: SearchResponseEventsData;
    pagination: PaginationData;
};

export type SearchResponseEventsData = {
    organic: FormattedEvent[];
    promoted: FormattedEvent[];
    topMatches: FormattedEvent[];
};

export const parseSearchDataToEvents = (
    searchData: OrganicEventSearchApiResponse,
): SearchResponseEvents => {
    const { events } = searchData;

    return {
        events: {
            organic: parseApiEventData(events.results),
            promoted: parseApiEventData(events.promoted_results),
            topMatches: parseApiEventData(events.top_match),
        },
        pagination: {
            page: events.pagination.page_number,
            pageSize: events.pagination.page_size,
            pageCount: events.pagination.page_count,
            objectCount: events.pagination.object_count,
            continuation: events.pagination.continuation,
        },
    };
};

function parseApiEventData(
    events: DestinationEvent[] | undefined = [],
): FormattedEvent[] {
    return events.map(transformDestinationEvent);
}
